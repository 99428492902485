import styled from 'styled-components';

const ITEMS = [
  {
    title: 'Біозахист',
    description:
      'Вбудований HEPA-фільтр медичного класу забезпечує захист від 99,97% частинок, що містяться в повітрі.',
    img: '/modelPages/Cybertruck/image2.jpg',
  },
  {
    title: 'Заряджай все',
    description:
      'Швидко заряджайте телефон, ноутбук або інструменти з переднього сидіння, заднього сидіння або ліжка за допомогою бездротової зарядки, розеток usb-c потужністю 65 Вт та 120 В чи 220 В.',
    img: '/modelPages/Cybertruck/image3.jpg',
  },
  {
    title: 'Великий багажник',
    description:
      'Багажник Tesla Cybertruck вражає своєю вмісткістю та зручністю. Він має великий простір для вантажів і можливість управління через додаток на смартфоні. Завдяки міцній конструкції з нержавіючої сталі, багажник Cybertruck захищає вантаж від різних впливів та забезпечує надійний захист.',
    img: '/modelPages/Cybertruck/image4.jpg',
  },
];

const SecondInfo = () => {
  return (
    <Wrapper>
      <MainContainer>
        <PictureMain src="/modelPages/Cybertruck/in.jpg" />
        <MainTextBlock>
          <Text1>
          Міцний зовні,
            <br /> зручний всередині
          </Text1>
          <Text2>
          Пориньте у просторий, сучасний салон, наповнений передовими технологічними функціями та розвагами.
          </Text2>
        </MainTextBlock>
      </MainContainer>
      <SecondContainer>
        {ITEMS.map((el, index) => (
          <SecondBlock key={index}>
            <Picture2 src={el.img} />
            <Text3>{el.title}</Text3>
            <Text2>{el.description}</Text2>
          </SecondBlock>
        ))}
      </SecondContainer>
    </Wrapper>
  );
};
export default SecondInfo;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 100px;
  padding: 0 180px;
  margin-top: 150px;
  @media (max-width: 1650px) {
    padding: 0 40px;
    margin-top: 120px;
  }
  @media (max-width: 860px) {
    margin-top: 60px;
  }
`;
const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 53px;
  @media (max-width: 860px) {
    flex-direction: column-reverse;
    row-gap: 30px;
  }
`;
const PictureMain = styled.img`
  width: 60%;
  @media (max-width: 860px) {
    width: calc(100% + 80px);
    margin: 0 -40px;
  }
`;
const Picture2 = styled.img`
  width: 100%;
`;
const MainTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  width: 40%;
  @media (max-width: 860px) {
    width: 100%;
  }
`;
const Text1 = styled.div`
  font-size: 48px;
  font-weight: 500;
  line-height: 67.2px;
  text-align: start;
  @media (max-width: 1160px) {
    font-size: 32px;
    line-height: 30.2px;
  }
`;
const Text2 = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  color: rgba(147, 146, 152, 1);
  text-align: start;
  @media (max-width: 1160px) {
    font-size: 14px;
    line-height: 20.6px;
  }
`;
const Text3 = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 33.2px;
  text-align: start;
  @media (max-width: 1160px) {
    font-size: 18px;
    line-height: 20.6px;
  }
`;
const SecondContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  @media (max-width: 775px) {
    flex-direction: column;
  }
`;
const SecondBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 30%;
  @media (max-width: 775px) {
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(212, 211, 214, 1);
  }
`;
