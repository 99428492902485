import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import styled from 'styled-components';
import { useRef } from 'react';
import VideoComponent from '../../../NewsPage/VideoComponents';

const DATA_SLIDE = [
  {
    video: `<iframe
      width="100%"
      height="100%"
      src="https://www.youtube.com/embed/PL6fAPK_3rU"
      title="Саморобний автопілот Tesla | ТЕСТ у реальних умовах на дорогах України"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>`,
    title: '',
  },
  {
    video: `<iframe width="100%" height="100%" src="https://www.youtube.com/embed/UAoBNpBjyic?si=L1-9j4IAjZfKpLe2" title="Тесла проти всіх! Автопробіг 15 самих ефективних электромобілів." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    title: '',
  },
  {
    video: `<iframe width="100%" height="100%" src="https://www.youtube.com/embed/OanDeNAAgs8?si=lWsc9HHrYe2Q7NAh" title="Tesla Model S з Європи | Скільки коштує, комплектація, салон та РЕАКЦІЯ КЛІЄНТА" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    title: '',
  },
  {
    video: `<iframe
      width="100%"
      height="100%"
      src="https://www.youtube.com/embed/PL6fAPK_3rU"
      title="Саморобний автопілот Tesla | ТЕСТ у реальних умовах на дорогах України"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>`,
    title: '',
  },
];

const SliderItem = ({ data }) => {
  return (
    <SliderItemWrapper>
      <VideoComponent iframeCode={data.video} />
      <SliderTitle>{data.title}</SliderTitle>
    </SliderItemWrapper>
  );
};

const Slider1 = () => {
  const extractTitleFromIframe = (iframeString) => {
    const match = iframeString.match(/title="(.*?)"/);
    return match ? match[1] : null;
  };

  const updatedDataSlide = DATA_SLIDE.map((slide) => {
    const extractedTitle = extractTitleFromIframe(slide.video);
    return {
      ...slide,
      title: extractedTitle || slide.title,
    };
  });

  console.log(updatedDataSlide);
  const navigationNextRef = useRef(null);
  const navigationPrevRef = useRef(null);
  return (
    <>
      <MainTitle>Дивіться нас на ютубі</MainTitle>

      <Wrapper>
        <ArrowL ref={navigationPrevRef} />
        <ArrowR ref={navigationNextRef} />
        <Swiper
          pagination={{ clickable: true }}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          spaceBetween={30}
          slidesPerView={'auto'}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
        >
          {updatedDataSlide.map((el, index) => (
            <SwiperSlide key={index} style={{ width: 'auto', height: '100%' }}>
              <SliderItem data={el} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Wrapper>
    </>
  );
};
export default Slider1;

const SliderItemWrapper = styled.div`
  width: 468px;
  height: auto;
  @media (max-width: 700px) {
    width: 260px;
  }
`;
const SliderPicture = styled.img`
  width: 100%;
`;
const SliderTitle = styled.div`
  font-weight: 500;
  margin-top: 25px;
  font-size: 24px;
  color: black;
  text-align: start;
  line-height: 33.6px;
  @media (max-width: 700px) {
    margin-top: 15px;
    font-size: 15px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.vidhuky ? '0' : '0 180px')};
  transition: all 1s;
  position: relative;
  @media screen and (max-width: 1650px) {
    padding: ${(props) => (props.vidhuky ? '0' : '0 40px')};
  }
  @media screen and (max-width: 380px) {
    padding: ${(props) => (props.vidhuky ? '0' : '0 27px')};
  }
`;
const MainTitle = styled.div`
  font-weight: 500;
  font-size: 48px;
  text-align: start;
  margin: 150px auto 60px 0;
  padding: 0 180px;
  @media screen and (max-width: 1650px) {
    padding: 0 40px;
  }
  @media screen and (max-width: 7000px) {
    font-size: 24px;
    margin: 150px auto 30px 0;
  }
`;
const ArrowL = styled.div`
  width: 45px;
  height: 45px;
  margin-top: 100px;
  margin: 32px;
  transition: 0.3s;
  background-image: url('/images/Arrow.jpg');
  cursor: pointer;
  position: absolute;
  background-position: center;
  height: 45px;
  z-index: 200;
  left: 125px;
  top: 150px;
  @media screen and (max-width: 1650px) {
    left: ${(props) => (props.vidhuky ? '-53px' : '-13px')};
  }
  @media screen and (max-width: 700px) {
    top: 105px;
  }

  @media screen and (max-width: 380px) {
    width: 35px;
    height: 35px;
  }
`;
const ArrowR = styled.div`
  width: 45px;
  transition: 0.3s;
  background-image: url('/images/Arrow_rev.jpg');
  background-position: center;
  cursor: pointer;
  margin-top: 100px;
  margin: 32px;
  position: absolute;
  height: 45px;
  z-index: 200;
  right: 125px;
  top: 150px;
  @media screen and (max-width: 1650px) {
    right: ${(props) => (props.vidhuky ? '-53px' : '-13px')};
    top: 150px;
  }
  @media screen and (max-width: 700px) {
    top: 105px;
  }

  @media screen and (max-width: 380px) {
    width: 35px;
    height: 35px;
  }
`;
