import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  @media screen and (max-width: 1020px) {
    padding-right: 0;
    display: block;
  }
`;
export const BarWrapper = styled.div`
  margin-top: 60px;
  height: 60px;
`;
