import styled from 'styled-components';

const INFO_ITEMS = [
  {
    text: 'Безпека є найважливішою частиною кожної Tesla. Ми проектуємо наші транспортні засоби, щоб перевищувати стандарти безпеки.',
    img: '/modelPages/modelS/icon1.png',
    title: 'Безпека',
  },
  {
    text: '5-зірковий рейтинг Tesla отримав 5-зірковий рейтинг безпеки NHTSA в кожній категорії та підкатегорії.',
    img: '/modelPages/modelS/icon2.png',
    title: 'Рейтинг',
  },
  {
    text: 'Top Safety Pick+ Tesla отримала нагороду IIHS Top Safety Pick+ з найвищими оцінками в усіх категоріях ударостійкості та запобігання фронтальним зіткненням.',
    img: '/modelPages/modelS/icon3.png',
    title: 'Нагороди',
  },
];
const VISION_ITEMS = [
  {
    title: '360°',
    desc: 'Задня, бокова та передня камери забезпечують максимальну видимість',
  },
  {
    title: '250 м',
    desc: 'Потужна візуальна обробка на відстані до 250 метрів',
  },
  {
    title: 'Tesla Vision',
    desc: 'Tesla Vision виявляє прилеглі автомобілі, допомагає запобігти зіткненням та допомозі при паркуванні',
  },
];

const Safety = () => {
  return (
    <Wrapper>
      <FirstContainer>
        <InfoContainer>
          <>Створено для безпеки</>
          <ResText>
            Завдяки піднятому положенню сидіння та низькій панелі приладів водій
            має чудовий огляд дороги попереду. Інтер’єр Model Y простий і
            зрозумілий, з 15-дюймовим сенсорним екраном, захоплюючою
            аудіосистемою та широким повністю скляним дахом, який створює
            додатковий простір для голови та забезпечує безперебійний вид на
            небо.
          </ResText>
          {INFO_ITEMS.map((el, index) => (
            <FirstInfo key={index}>
              <Icon src={el.img} />
              <Column>
                <TitleRes>{el.title}</TitleRes>
                <>{el.text}</>
              </Column>
            </FirstInfo>
          ))}
        </InfoContainer>

        <Picture src="/modelPages/modelS/Safety.png" />
      </FirstContainer>
      <TitleText>Tesla Vision</TitleText>
      <ResText>
        Завдяки піднятому положенню сидіння та низькій панелі приладів водій має
        чудовий огляд дороги попереду. Інтер’єр Model Y простий і зрозумілий, з
        15-дюймовим сенсорним екраном, захоплюючою аудіосистемою та широким
        повністю скляним дахом, який створює додатковий простір для голови та
        забезпечує безперебійний вид на небо.
      </ResText>
      <SecondContainer>
        {VISION_ITEMS.map((el, index) => (
          <SecondBlock key={index}>
            <SecondText1>{el.title}</SecondText1>
            <SecondText2>{el.desc}</SecondText2>
          </SecondBlock>
        ))}
      </SecondContainer>
    </Wrapper>
  );
};
export default Safety;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 150px 180px;
  @media (max-width: 1650px) {
    padding: 80px 40px;
  }
`;
const Picture = styled.img`
  width: 50%;
  @media (max-width: 1250px) {
    width: calc(100% + 80px);
    margin: 0 -40px;
  }
`;
const Icon = styled.img`
  width: 30px;
  height: 30px;
  @media (max-width: 1250px) {
    width: 25px;
    height: 25px;
  }
`;
const FirstContainer = styled.div`
  display: flex;
  column-gap: 150px;
  font-size: 48px;
  font-weight: 500;
  color: black;
  width: 100%;
  padding-bottom: 150px;
  @media (max-width: 1250px) {
    flex-direction: column;
    padding-bottom: 80px;
    font-size: 24px;
  }
`;

const FirstInfo = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 500;
  column-gap: 30px;
  line-height: 25.6px;
  padding: 35px 0;
  border-bottom: 1px solid rgba(212, 211, 214, 1);
  text-align: start;
  @media (max-width: 1250px) {
    column-gap: 20px;
    padding: 30px 0;
    font-size: 12px;
    line-height: 19.2px;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 1250px) {
    width: 100%;
  }
`;
const SecondContainer = styled.div`
  display: flex;
  column-gap: 40px;
  margin-top: 60px;
  justify-content: space-between;
  @media (max-width: 1250px) {
    flex-direction: column;
    row-gap: 45px;
    margin-top: 10px;
  }
`;
const SecondBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 10px;
  width: 100%;
`;
const SecondText1 = styled.div`
  font-size: 32px;
  font-weight: 500;
  color: black;
  text-align: start;
  @media (max-width: 1250px) {
    font-size: 24px;
  }
`;
const SecondText2 = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: rgba(147, 146, 152, 1);
  text-align: start;
  @media (max-width: 1250px) {
    font-size: 10px;
  }
`;
const TitleText = styled.div`
  font-size: 48px;
  font-weight: 500;
  color: black;
  margin-right: auto;
  @media (max-width: 1250px) {
    font-size: 24px;
  }
`;
const ResText = styled.div`
  display: none;
  @media (max-width: 1250px) {
    display: block;
    font-size: 12px;
    font-weight: 400;
    color: rgba(147, 146, 152, 1);
    text-align: start;
    margin: 15px 0 35px 0;
  }
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const TitleRes = styled.div`
  display: none;
  font-size: 18px;
  font-weight: 500;
  color: black;
  line-height: 28.8px;
  @media (max-width: 550px) {
    display: block;
  }
`;
