export const SelectData = [
  [
    ["9:00"],
    ["10:00"],
    ["11:00"],
    ["12:00"],
    ["13:00"],
    ["14:00"],
    ["15:00"],
    ["16:00"],
    ["17:00"],
    ["18:00"],
  ],
  [["Model S"], ["Model X"], ["Model 3"], ["Model Y"]],
];
