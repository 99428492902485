import React from 'react';
import Iframe from 'react-iframe';
import styled from 'styled-components';

function VideoComponent({ iframeCode }) {
  console.log()
    return (
      <Wrapper>
        <Frame  dangerouslySetInnerHTML={{ __html: iframeCode }} />
      </Wrapper>
    );
  }
  
export default VideoComponent;

const Wrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin: 59px 0;
position: relative;
  width: 100%;
  padding-bottom: 56.25%; 
`;
const Frame = styled.div`
position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

`;