import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import i18n from '../../../i18n/i18n';
import Footer from '../../common/Footer';
import HelmTags from '../../Helmet';
import Header from '../../common/Header';
import InfoBg from './components/InfoBg';
import SecondHeader from './components/SecondHeader';
import SecondInfo from './components/SecondInfo';
import Characteristics from './components/Characteristics';
import Exterier from './components/Exterier';
import Safety from './components/Safety';
import Features from './components/Features';
import Slider1 from './components/Slider';
import Slider2 from './components/Slider2';
import ImageCredits from './components/imageCredits';

const INFO = [
  { title: '539 ', span: 'км', desc: 'Запас ходу' },
  { title: '456 ', span: 'к. с.', desc: 'Пікова потужність' },
  { title: '2,5 ', span: 'c', desc: 'Від 0 - 100 км/год' },
  { title: '249 ', span: 'км/год', desc: 'Макс швидкість' },
];

const ModelY = ({ tags }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  const { t } = useTranslation();
  return (
    <>
      {tags ? (
        <HelmTags
          title={tags.modelY[0]}
          titleru={tags.modelYru[0]}
          desc={tags.modelY[1]}
          descru={tags.modelYru[1]}
          img={tags.modelY[2]}
        />
      ) : null}
      <Header />
      <SecondHeader breadName="Model Y" />
      <MainBg
        bg={'/images/bg/ModelY_Bg_2024.jpg'}
        bgm={'/images/bg/ModelY_Bg_mob_2024.jpg'}
      >
        <InfoBg info={INFO} />
      </MainBg>
      <SecondInfo />
      <ETContainer src="/modelPages/modelY/bg_ET.jpg" />
      <ETContainerRes src="/modelPages/modelY/bg_ET_mob.jpg" />
      <Characteristics />
      <Exterier />
      <Safety />
      <BgBlock src="/modelPages/modelY/bg_block.jpg" />
      <BgFuture src="/modelPages/modelY/bg_future.jpg" />
      <BlockFuture>
        <>Автопілот. Майбутнє водіння</>
        <FutureText>
          Автопілот дозволяє вашому автомобілю керувати, прискорюватися та
          гальмувати автоматично в межах своєї смуги руху під вашим активним
          наглядом, допомагаючи з найбільш обтяжливими частинами водіння. З
          бездротовим програмним забезпеченням оновлення, останні покращення
          доступні миттєво.
        </FutureText>
        <ResFuturePicture src="/modelPages/modelY/bg_future_mob.jpg" />
      </BlockFuture>
      <Features />
      <Slider1 />
      <Slider2 />
      <ImageCredits />
      <Footer />
    </>
  );
};
export default ModelY;
const MainBg = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  background-image: url(${(p) => p.bg});
  background-position: center;
  height: 570px;
  @media screen and (max-width: 650px) {
    background-image: url(${(p) => p.bgm});
    height: 443px;
    background-size: cover;
    flex-direction: column;

    align-items: center;
  }
`;
const ETContainer = styled.img`
  width: 100%;
  min-height: 446px;
  object-fit: cover;
  object-position: 10%;
  margin-top: 150px;
  margin-bottom: -10px;
  @media (max-width: 350px) {
    display: none;
  }
`;
const ETContainerRes = styled.img`
  display: none;
  width: 100%;
  height: 446px;
  margin-top: 80px;
  object-fit: cover;
  object-position: 10%;
  @media (max-width: 350px) {
    display: block;
  }
`;
const BgBlock = styled.img`
  width: 100%;
  height: 630px;
  object-fit: cover;
  margin-bottom: -10px;
  @media (max-width: 1100px) {
    height: 400px;
  }
  @media (max-width: 520px) {
    height: 320px;
  }
`;
const BgFuture = styled.img`
  width: 100%;
  height: 491px;
  object-fit: cover;
  object-position: 10%;
  margin-bottom: -10px;
  @media (max-width: 1650px) {
    height: 400px;
  }
  @media (max-width: 620px) {
    height: 300px;
  }
  @media (max-width: 500px) {
    display: none;
  }
`;
const BlockFuture = styled.div`
  display: none;
  flex-direction: column;
  background-color: rgba(28, 27, 34, 1);
  padding: 45px 40px;
  font-size: 24px;
  font-weight: 500;
  text-align: start;
  color: white;
  row-gap: 15px;
  @media (max-width: 500px) {
    display: flex;
  }
`;
const FutureText = styled.div`
  font-size: 12px;
  font-weight: 400;
`;
const ResFuturePicture = styled.img`
  width: calc(100% + 80px);
  margin: 0 -40px;
`;
