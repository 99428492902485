import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useContentful from '../../../contentful';
import i18n from '../../../i18n/i18n';
import ReactMarkdown from 'react-markdown';
import {
  Button,
  CardItem,
  CText,
  FText,
  Image,
  ImageS,
  LIWrapper,
  Name,
  NavText,
  PText,
  SButton,
  SCardItem,
  SName,
  SText,
  Text,
  TextWrapper,
  Title,
  VectorArrow,
  Wrapper,
} from './views';
import {
  HeaderTitle,
  NavBar,
  NavTextActive,
  NavTextInActive,
} from '../../NewsPage/Catalog/views';

const Status = () => {
  return <WrapperStatus>Тесла</WrapperStatus>;
};

const LCard = ({ data }) => {
  const { t } = useTranslation();
  return (
    <WrapperCardL>
      <Link to={`${data.id}`}>
        <ImageLC img={data.image}>
          <Status />
        </ImageLC>
      </Link>

      <TitleLC>
        <ReactMarkdown>
          {i18n.language === 'ua' ? data.title : data.titleru}
        </ReactMarkdown>
      </TitleLC>
      <TextLC>
        <ReactMarkdown>
          {i18n.language === 'ua' ? data.text : data.textru}
        </ReactMarkdown>
      </TextLC>
      <Link to={`statti-ta-novyny/${data.id}`}>
        <Button>
          {t('mainscreen.news.detalis')} <VectorArrow />
        </Button>
      </Link>
    </WrapperCardL>
  );
};
const RCard = ({ data }) => {
  const { t } = useTranslation();
  return (
    <WrapperCardR>
      <Link to={`${data.id}`}>
        <ImageRC img={data.image}>
          <Status />
        </ImageRC>
      </Link>

      <WrapperCardRContent>
        <TitleRC>
          <ReactMarkdown>
            {i18n.language === 'ua' ? data.title : data.titleru}
          </ReactMarkdown>
        </TitleRC>
        <TextRC>
          <ReactMarkdown>
            {i18n.language === 'ua' ? data.text : data.textru}
          </ReactMarkdown>
        </TextRC>
        <Link to={`statti-ta-novyny/${data.id}`}>
          <Button style={{ marginTop: '23px' }}>
            {t('mainscreen.news.detalis')} <VectorArrow />
          </Button>
        </Link>
      </WrapperCardRContent>
    </WrapperCardR>
  );
};

const News = () => {
  const { t } = useTranslation();
  const [topicsItems, setTopicsItems] = useState([]);
  const [newsItems, setNewsItems] = useState([]);

  const [NewsActive, setNewsActive] = useState(true);
  const [TopicsActive, setTopicsActive] = useState(false);

  const [loading, setLoading] = useState(false);

  const { getItemsTopics, getItemsNews } = useContentful();
  useEffect(() => {
    getItemsTopics(setLoading).then((response) => {
      setTopicsItems(response);
    });
  }, []);
  useEffect(() => {
    getItemsNews(setLoading).then((response) => {
      setNewsItems(response);
    });
  }, []);

  const newsActive = () => {
    setNewsActive(true);
    setTopicsActive(false);
  };
  const topicsActive = () => {
    setNewsActive(false);
    setTopicsActive(true);
  };

  return (
    <>
      {newsItems.length && topicsItems.length ? (
        <>
          <Wrapper>
            <WrapperContent>
              <LWrapper>
                <HeaderTitle>{t('mainscreen.news.topic')}</HeaderTitle>
                <LCardWrapper>
                  {topicsItems.slice(0, 4).map((el) => (
                    <LCard data={el} />
                  ))}
                </LCardWrapper>
              </LWrapper>
              <RWrapper>
                <HeaderTitle>{t('mainscreen.news.news')}</HeaderTitle>
                <RCardWrapper>
                  {newsItems.slice(0, 4).map((el) => (
                    <RCard data={el} />
                  ))}
                </RCardWrapper>
              </RWrapper>
            </WrapperContent>
            <WrapperContentRes>
              <HeaderTitle>{t('mainscreen.news.title')}</HeaderTitle>
              <NavBar>
                {NewsActive ? (
                  <NavTextActive>{t('mainscreen.news.news')}</NavTextActive>
                ) : (
                  <NavTextInActive onClick={newsActive}>
                    {t('mainscreen.news.news')}
                  </NavTextInActive>
                )}
                {TopicsActive ? (
                  <NavTextActive>{t('mainscreen.news.topic')}</NavTextActive>
                ) : (
                  <NavTextInActive onClick={topicsActive}>
                    {t('mainscreen.news.topic')}
                  </NavTextInActive>
                )}
              </NavBar>
              {NewsActive ? (
                <RCardWrapper>
                  {newsItems.slice(0, 4).map((el, index) => (
                    <LCard key={index} data={el} />
                  ))}
                </RCardWrapper>
              ) : (
                <RCardWrapper>
                  {topicsItems.slice(0, 4).map((el, index) => (
                    <LCard key={index} data={el} />
                  ))}
                </RCardWrapper>
              )}
            </WrapperContentRes>
          </Wrapper>
        </>
      ) : null}
    </>
  );
};
export default News;

export const LWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const RWrapper = styled.div`
  width: 523px;
  display: flex;
  flex-direction: column;
`;

export const WrapperContent = styled.div`
  display: flex;
  column-gap: 51px;
  @media screen and (max-width: 1100px) {
    display: none;
  }
`;

export const WrapperContentRes = styled.div`
  display: none;
  column-gap: 51px;
  @media screen and (max-width: 1100px) {
    display: flex;
    flex-direction: column;
  }
`;

// =========Left Card==============
export const LCardWrapper = styled.div`
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  grid-column-gap: 51px;
  grid-row-gap: 50px;
  @media screen and (max-width: 1428px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const WrapperCardL = styled.div`
  width: 100%;
  max-width: 486px;
  text-align: left;
  @media screen and (max-width: 1428px) {
    max-width: fit-content;
  }
`;

export const TitleLC = styled.div`
  margin-top: 35px;
  font-size: 20px;
  color: #272531;
  a {
    color: red;
  }
`;
export const TextLC = styled.div`
  margin-top: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  box-orient: vertical;
  font-size: 16px;
  color: #939298;
  a {
    color: red;
  }
`;
// ==========Right Card===========
export const RCardWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 51px;
`;
export const WrapperCardR = styled.div`
  width: 523px;
  text-align: left;
  display: flex;
  column-gap: 24px;
`;
export const WrapperCardRContent = styled.div`
  max-width: 233px;
`;

export const TitleRC = styled.div`
  font-size: 20px;
  color: #272531;
  a {
    color: red;
  }
`;
export const TextRC = styled.div`
  height: 39px;
  margin-top: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  box-orient: vertical;
  font-size: 16px;
  color: #939298;
  a {
    color: red;
  }
`;

const ImageRC = styled.div`
  width: 248px;
  height: 145px;
  background-image: url(${(props) => props.img});
  background-size: cover;
  background-position: center;
`;

const ImageLC = styled.div`
  height: 341px;
  background-image: url(${(props) => props.img});
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 1100px) {
    height: 450px;
  }
  @media screen and (max-width: 600px) {
    height: 290px;
  }
  @media screen and (max-width: 465px) {
    height: 240px;
  }
  @media screen and (max-width: 365px) {
    height: 190px;
  }
`;

const WrapperStatus = styled.div`
  background-color: #e20531;
  color: white;
  padding: 8px 43px;
  width: fit-content;
`;
