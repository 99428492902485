export const characteristics = [
  { Btitle: "1.99 с", Stitle: "Від 0-100 км/г" },
  { Btitle: "850 л", Stitle: `Найкращий у своєму класі вантажний відсік` },
  { Btitle: "595 км", Stitle: "Запас ходу" },
];
export const mainScreenData = [
  {
    img: "/images/bg/ModelS_Bg.jpg",
    mimg: "/images/bg/ModelS_Bg_mob.jpg",
    label: "Model S",
    text1: "1.99 c",
    title1: "Від 0-100 км/г",
    text2: "850 л",
    title2: "Найкращий у своєму класі вантажний відсік",
    text3: "595 км",
    title3: "Запас Ходу",
  },
  {
    img: "/images/bg/Model3_Bg.jpg",
    mimg: "/images/bg/Model3_Bg_mob.jpg",
    label: "Model 3",
    text1: "3.4 с",
    title1: "Від 0-100 км/г",
    text2: "425 л",
    title2: "Найкращий у своєму класі вантажний відсік",
    text3: "565 км",
    title3: "Запас ходу",
  },
  {
    img: "/images/bg/ModelX_Bg.jpg",
    mimg: "/images/bg/ModelX_Bg_mob.jpg",
    label: "Model X",
    text1: "2.5 c",
    title1: "Від 0-100 км/г",
    text2: "2491 л",
    title2: "Найкращий у своєму класі вантажний відсік",
    text3: "547 км",
    title3: "Запас ходу",
  },
  {
    img: "/images/bg/ModelY_Bg.jpg",
    mimg: "/images/bg/ModelY_Bg_mob.jpg",
    label: "Model Y",
    text1: "3.5 c",
    title1: "Від 0-100 км/г",
    text2: "1840 л",
    title2: "Найкращий у своєму класі вантажний відсік",
    text3: "540 км",
    title3: "Запас ходу",
  },
  {
    img: "/images/bg/Roadster_Bg.jpg",
    mimg: "/images/bg/Roadster_Bg_mob.jpg",
    label: "Roadster",
    text1: "1.9 c",
    title1: "Від 0-100 км/г",
    text2: "402 км/г",
    title2: "Максимальна швидкість",
    text3: "997 км",
    title3: "Запас ходу",
  },
  {
    img: "/images/bg/Cybertruck_Bg.jpg",
    mimg: "/images/bg/Cybertruck_Bg_mob.jpg",
    label: "Cybertruck",
    text1: "6.5 с",
    title1: "Від 0-100 км/г",
    text2: "2832 л",
    title2: "Найкращий у своєму класі вантажний відсік",
    text3: "400 км",
    title3: "Запас ходу",
  },
];
