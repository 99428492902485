import styled from 'styled-components';

const InfoBg = ({ info }) => {
  return (
    <Wrapper>
      <Res1>
        {info.map((el, index) => (
          <BlockText key={index} last={index === info.length - 1}>
            <Text1>
              {el.title}
              <Span1>{el.span}</Span1>
            </Text1>
            <Text2>{el.desc}</Text2>
          </BlockText>
        ))}
      </Res1>
      <Res2>
        <RowContainer
          style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.5)' }}
        >
          <BlockText>
            <Text1>
              {info[0].title}
              <Span1>{info[0].span}</Span1>
            </Text1>
            <Text2>{info[0].desc}</Text2>
          </BlockText>
          <BlockText>
            <Text1>
              {info[1].title}
              <Span1>{info[1].span}</Span1>
            </Text1>
            <Text2>{info[1].desc}</Text2>
          </BlockText>
        </RowContainer>
        <RowContainer>
          <BlockText>
            <Text1>
              {info[2].title}
              <Span1>{info[2].span}</Span1>
            </Text1>
            <Text2>{info[2].desc}</Text2>
          </BlockText>
          <BlockText>
            <Text1>
              {info[3].title}
              <Span1>{info[3].span}</Span1>
            </Text1>
            <Text2>{info[3].desc}</Text2>
          </BlockText>
        </RowContainer>
      </Res2>
    </Wrapper>
  );
};
export default InfoBg;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  padding: 0 140px;
  box-sizing: border-box;
  @media (max-width: 1650px) {
    padding: 0 40px;
  }
  @media (max-width: 650px) {
    margin-left: 0;
    width: 100%;
  }
`;
const Span1 = styled.span`
  font-size: 32px;
  font-weight: 500;
  color: white;
  @media (max-width: 650px) {
    font-size: 12px;
  }
`;
const Text1 = styled.div`
  font-size: 32px;
  font-weight: 500;
  color: white;
  white-space: nowrap;
  @media (max-width: 650px) {
    font-size: 24px;
  }
`;
const Text2 = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: white;
  @media (max-width: 650px) {
    font-size: 10px;
  }
`;
const BlockText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 10px;
  padding: 34px 0;
  border-bottom: ${({ last }) =>
    last ? 'none' : '1px solid rgba(255, 255, 255, 0.5)'};
  @media (max-width: 650px) {
    border-bottom: none;
    padding: 20px 0;
    width: 100%;
  }
`;
const Res1 = styled.div`
  display: block;
  @media (max-width: 650px) {
    display: none;
  }
`;
const Res2 = styled.div`
  display: none;
  @media (max-width: 650px) {
    display: block;
  }
`;
const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
