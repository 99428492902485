import styled from 'styled-components';

const Exterier = () => {
  return (
    <>
      <Header>
        <HeaderText>Більше про екстер'єр</HeaderText>
      </Header>
      <Wrapper>
        <Picture src="/modelPages/model3/Ex1_2024.jpg" />
        <Picture src="/modelPages/model3/Ex2_2024.jpg" />
        <Picture src="/modelPages/model3/Ex3_2024.jpg" />
        <PictureRes src="/modelPages/model3/Ex1_mob_2024.jpg" />
        <PictureRes src="/modelPages/model3/Ex2_mob_2024.jpg" />
        <PictureRes src="/modelPages/model3/Ex3_mob_2024.jpg" />
      </Wrapper>
    </>
  );
};
export default Exterier;

const Header = styled.div`
  background-color: rgba(28, 27, 34, 1);
  padding: 150px 180px 60px;
  @media (max-width: 1650px) {
    padding: 70px 40px 30px;
  }
`;
const HeaderText = styled.div`
  font-size: 48px;
  font-weight: 500;
  color: white;
  @media (max-width: 1000px) {
    font-size: 32px;
  }
  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

const Wrapper = styled.div`
  background-color: rgba(28, 27, 34, 1);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;
const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  background-image: url(${(p) => p.bg});
  background-size: cover;
  background-position: center;
`;
const BlockText = styled.div`
  display: flex;
  flex-direction: column;
`;
const Text1 = styled.div``;
const Text2 = styled.div``;
const Picture = styled.img`
  @media (max-width: 640px) {
    display: none;
  }
`;
const PictureRes = styled.img`
  display: none;
  @media (max-width: 640px) {
    display: block;
  }
`;
