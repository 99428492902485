export const footerTitles1 = [
  { label: 'Model S', link: '/modelS' },
  { label: 'Model 3', link: '/model3' },
  { label: 'Model X', link: '/modelX' },
  { label: 'Model Y', link: '/modelY' },
  { label: 'Roadster', link: '/roadster' },
  { label: 'Cybertruck', link: '/cybertruck' },
];
export const footerTitles2 = [
  [
    { label: 'Каталог авто', link: '/katalog-auto' },
    { label: 'Новини та статті', link: '/statti-ta-novyny' },
    { label: 'Аксесуари', link: '/katalog-aksesuary' },
    { label: 'Мерч', link: '/katalog-merch' },
    { label: 'Запис на тест-драйв', link: '/zapys-na-test-drayv' },
  ],
  [
    { label: 'Каталог авто', link: '/katalog-auto' },
    { label: 'Новости и статьи', link: '/statti-ta-novyny' },
    { label: 'Аксессуары', link: '/katalog-aksesuary' },
    { label: 'Мерч', link: '/katalog-merch' },
    { label: 'Запись на тест-драйв', link: '/zapys-na-test-drayv' },
  ],
];
export const footerTitles3 = [
  [
    { label: 'Запис на ТО', link: '' },
    { label: 'Відгуки', link: '/vidhuky' },
    { label: 'Запчастини для TESLA', link: '/katalog-zapchastyny' },
    { label: 'Контакти', link: '/kontakty' },
    // { label: "Конфігуратор нових авто", link: "/konfigurator" },
  ],
  [
    { label: 'Запись на ТО', link: '' },
    { label: 'Отзывы', link: '/vidhuky' },
    { label: 'Запчасти для TESLA', link: '/katalog-zapchastyny' },
    { label: 'Контакты', link: '/kontakty' },
    // { label: "Конфигуратор новых авто", link: "/konfigurator" },
  ],
];
export const footerTitles4 = [
  ['м. Київ, вул. Протасів Яр 13'],
  ['г. Киев, ул. Протасов Яр 13'],
];
export const images = [
  {
    img: '/images/social_icons/Vb.png',
    hover: '/images/social_icons/Vb_hover.png',
    link: 'viber://chat?number=%2B380689778888',
  },
  {
    img: '/images/social_icons/Fb.png',
    hover: '/images/social_icons/Fb_hover.png',
    link: 'https://www.facebook.com/profile.php?id=100090376021781',
  },
  {
    img: '/images/social_icons/Tg.png',
    hover: '/images/social_icons/Tg_hover.png',
  },
  {
    img: '/images/social_icons/Inst.png',
    hover: '/images/social_icons/Inst_hover.png',
    link: 'https://www.instagram.com/tesla.drv/',
  },
  {
    img: '/images/social_icons/Yt.png',
    hover: '/images/social_icons/Yt_hover.png',
    link: 'https://www.youtube.com/@TeslaDriveUkraine',
  },
];
export const contactsImages = [
  {
    img: '/images/social_icons/Vb.png',
    hover: '/images/social_icons/Vb_hover.png',
    link: 'viber://chat?number=%2B380689778888',
  },
  {
    img: '/images/social_icons/Fb.png',
    hover: '/images/social_icons/Fb_hover.png',
    link: 'https://www.facebook.com/profile.php?id=100090376021781',
  },
  {
    img: '/images/social_icons/Tg.png',
    hover: '/images/social_icons/Tg_hover.png',
  },
];
