export const characteristics = {
  modelS: {
    name: "Model S",
    range: "548",
    speed: "322",
    racing: "2.5",
    price: "132287",
    complect: "Long range",
    complect2: "Plaid",
    colorPrice: {
      white: "0",
      black: "1500",
      silver: "1500",
      blue: "1500",
      red: "3000",
    },
    wheelPrice: "4500",
    interiorPrice: {
      black: "0",
      white: "2000",
      cream: "2000",
    },
    range1: "580",
    speed1: "250",
    racing1: "3.8",
    price1: "109689",
    wheel: '19" диски Tempest Wheels',
    wheel1: '20" диски Cyberstream Wheels',
  },

  model3: {
    name: "Model 3",
    range: "507",
    speed: "261",
    racing: "3.1",
    price: "55045",
    complect: "Standart +",
    complect2: "Long range",
    complect3: "Performance",
    colorPrice: {
      white: "0",
      black: "1000",
      silver: "1000",
      blue: "1500",
      red: "2000",
    },
    wheelPrice: "1500",
    interiorPrice: {
      black: "0",
      white: "1000",
    },
    range1: "423",
    speed1: "225",
    racing1: "5.3",
    price1: "46910",
    wheel: '18" Aero Wheels',
    wheel1: '19" Aero Wheels',
    price2: "58661",
  },

  modelY: {
    name: "Model Y",
    range: "520",
    speed: "249",
    racing: "3.5",
    price: "65160",
    complect: "Long range",
    complect2: "Performance",
    colorPrice: {
      white: "0",
      black: "1000",
      silver: "1000",
      blue: "1500",
      red: "3000",
    },
    wheelPrice: "2000",
    interiorPrice: {
      black: "0",
      white: "1000",
    },
    range1: "525",
    seats7: "3000",
    speed1: "217",
    racing1: "4.8",
    price1: "59737",
    wheel: '19" диски Sport',
    wheel1: '20" диски Sport',
  },

  modelX: {
    name: "Model X",
    range: "627",
    speed: "262",
    racing: "1.99",
    price: "134999",
    complect: "Long range",
    complect2: "Plaid",
    colorPrice: {
      white: "0",
      black: "1500",
      silver: "1500",
      blue: "1500",
      red: "3000",
    },
    wheelPrice: "5500",
    interiorPrice: {
      black: "0",
      white: "2000",
      cream: "2000",
    },
    seats6: "6500",
    seats7: "3500",
    range1: "663",
    speed1: "250",
    racing1: "3.1",
    price1: "116920",
    wheel: '20" диски Cyberstream Wheels',
    wheel1: '22" диски Cyberstream Wheels',
  },
};
export const autopolitInfo1 = [
  [
    "Навигация на автопилоте",
    "Автоматическая смена полосы движения",
    "Автопарк",
    "Вызов",
    "Умный вызов",
  ],
  [
    "Навігація за допомогою автопілоту",
    "Автоматична зміна смуги руху",
    "Автопаркування",
    "Виклик",
    "Розумний виклик",
  ],
];
export const autopolitInfo2 = [
  [
    "Все функции базового автопилота и расширенного автопилота",
    "Управление светофором и стоп-сигналом",
  ],
  [
    "Усі функції базового автопілота та розширеного автопілота",
    "Керування світлофором та стоп-сигналом",
  ],
];
