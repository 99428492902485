import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SecondHeader = ({ breadName }) => {
  return (
    <Wrapper>
      <LinkString>
        <Link to="/">
          <Main>Головна</Main>
        </Link>
        <Next src="/images/next.svg" alt="asd" /> {breadName}
      </LinkString>
      <Text>Tesla Model X</Text>
    </Wrapper>
  );
};
export default SecondHeader;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  height: 266px;
  background-color: rgba(28, 27, 34, 1);
  padding: 5px 180px;
  padding-bottom: 60px;
  box-sizing: border-box;
  @media (max-width: 1650px) {
    padding: 0 40px;
    padding-bottom: 60px;
  }
  @media screen and (max-width: 681px) {
    height: 200px;
    padding-bottom: 40px;
  }
  @media (max-width: 400px) {
    padding: 0 40px;
    padding-bottom: 20px;
    height: 130px;
  }
`;
const LinkString = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  text-align: left;
  color: white;
  font-size: 16px;
  height: 11px;
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;
const Main = styled.div`
  color: #ffffff66;
  cursor: pointer;
  &:hover {
    color: white;
  }
`;
const Next = styled.img``;
const Text = styled.div`
  font-size: 96px;
  font-weight: 400;
  color: white;
  @media screen and (max-width: 681px) {
    font-size: 60px;
  }
  @media screen and (max-width: 481px) {
    font-size: 40px;
  }
  @media screen and (max-width: 400px) {
    font-size: 36px;
  }
`;
