import { useState } from 'react';
import styled from 'styled-components';

const Characteristics = () => {
  const [model, setModel] = useState('RWD');
  return (
    <>
      <Wrapper>
        <Container>
          <RowContainer>
            <Text1>Single-motor RWD</Text1>
            <Text2>
            Оснащений одним електричним двигуном та приводом задніх коліс, 
            що забезпечує надійне керування та динамічні характеристики.
            </Text2>
          </RowContainer>
          <RowContainer>
            <ColumnBlock>
              <Text1>6,5 c</Text1>
              <Text2>0-100 км/год</Text2>
            </ColumnBlock>
            <ColumnBlock>
              <Text1>400 км</Text1>
              <Text2>Запас ходу</Text2>
            </ColumnBlock>
            <ColumnBlock>
              <Text1>315 к. с.</Text1>
              <Text2>Пікова потужність</Text2>
            </ColumnBlock>
          </RowContainer>
        </Container>
        <Line />
        <Container>
          <RowContainer>
            <Text1>Dual-motor AWD</Text1>
            <Text2>
            Cybertruck в даній конфігурації пропонує два електричні двигуни та повний привід на всі колеса. 
            Це забезпечує покращене управління та потужність на дорозі, особливо у складних умовах.
            </Text2>
          </RowContainer>
          <RowContainer>
            <ColumnBlock>
              <Text1>4,1 c</Text1>
              <Text2>0-100 км/год</Text2>
            </ColumnBlock>
            <ColumnBlock>
              <Text1>550 км</Text1>
              <Text2>Запас ходу</Text2>
            </ColumnBlock>
            <ColumnBlock>
              <Text1>600 к. с.</Text1>
              <Text2>Пікова потужність</Text2>
            </ColumnBlock>
          </RowContainer>
        </Container>
      </Wrapper>
      <WrapperRes>
        <SelectContainer>
          <Select active={model === 'RWD'} onClick={() => setModel('RWD')}>
          Performace
          </Select>
          <Select active={model === 'AWD'} onClick={() => setModel('AWD')}>
          Long Range AWD
          </Select>
        </SelectContainer>
        {model === 'RWD' && (
          <>
            <TextRes>
              Платформа з подвійним двигуном і повним приводом має найбільший
              запас ходу, а тепер забезпечує потужність і прискорення.
            </TextRes>
            <ResRow>
              <RowText1>6,5 c</RowText1>
              <RowText2>0-100 км/год</RowText2>
            </ResRow>
            <ResRow>
              <RowText1>400 км</RowText1>
              <RowText2>Запас ходу</RowText2>
            </ResRow>
            <ResRow>
              <RowText1>315 к. с.</RowText1>
              <RowText2>Пікова потужність</RowText2>
            </ResRow>
          </>
        )}
        {model === 'AWD' && (
          <>
            <TextRes>
              Платформа повного приводу Tri Motor із векторним розподілом
              крутного моменту включає три незалежні двигуни, кожен із ротором
              із вуглецевою оболонкою. підтримує понад 1000 кінських сил на
              всьому шляху до максимальної швидкості.
            </TextRes>
            <ResRow>
              <RowText1>4,1 c</RowText1>
              <RowText2>0-100 км/год</RowText2>
            </ResRow>
            <ResRow>
              <RowText1>550 км</RowText1>
              <RowText2>Запас ходу</RowText2>
            </ResRow>
            <ResRow>
              <RowText1>600 к. с.</RowText1>
              <RowText2>Пікова потужність</RowText2>
            </ResRow>
          </>
        )}
      </WrapperRes>
    </>
  );
};
export default Characteristics;

const WrapperRes = styled.div`
  padding: 45px 40px;
  background-color: rgba(28, 27, 34, 1);
  display: none;
  flex-direction: column;
  @media (max-width: 1000px) {
    display: flex;
  }
`;
const SelectContainer = styled.div`
  display: flex;
  width: 100%;
`;
const Select = styled.div`
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 5px;
  width: 100%;
  color: ${(p) => (p.active ? 'white' : 'rgba(147, 146, 152, 1)')};
  border-bottom: 1px solid
    ${(p) => (p.active ? 'rgba(226, 5, 49, 1)' : 'rgba(73, 73, 78, 1)')};
  cursor: pointer;
  @media (max-width: 450px) {
    font-size: 18px;
  }
`;
const TextRes = styled.div`
  font-weight: 400;
  font-size: 18px;
  margin-top: 30px;
  color: white;
  @media (max-width: 450px) {
    font-size: 14px;
  }
`;
const RowText1 = styled.div`
  font-size: 30px;
  font-weight: 500;
  color: white;
  @media (max-width: 450px) {
    font-size: 20px;
  }
`;
const RowText2 = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: white;
  @media (max-width: 450px) {
    font-size: 12px;
  }
`;
const ResRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  align-items: end;
  border-bottom: 1px solid rgba(73, 73, 78, 1);
`;
/////////////////////

const Wrapper = styled.div`
  display: flex;
  padding: 100px 180px;
  column-gap: 30px;
  height: 260px;
  background-color: rgba(28, 27, 34, 1);
  @media (max-width: 1650px) {
    padding: 70px 40px;
  }
  @media (max-width: 1000px) {
    display: none;
  }
`;

const Line = styled.div`
  width: 1px;
  height: auto;
  background-color: white;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;
const Text1 = styled.div`
  font-size: 32px;
  font-weight: 500;
  color: white;
  text-align: start;
  white-space: nowrap;
`;
const Text2 = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: white;
  text-align: start;
`;
const RowContainer = styled.div`
  display: flex;
  column-gap: 50px;
  justify-content: space-between;
`;
const ColumnBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 10px;
`;
