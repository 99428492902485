import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const SearchComponent = ({ data }) => {
  const [openView, setOpenView] = useState(false);
  const [renderItems, setRenderItems] = useState([]);
  const [searchString, setSearchString] = useState('');
  console.log(data);
  useEffect(() => {
    // Фильтрация данных по полям name и nameru
    const filteredData = data.filter(
      (item) =>
        item.name.toLowerCase().includes(searchString.toLowerCase()) ||
        item.nameru?.toLowerCase().includes(searchString.toLowerCase()),
    );
    console.log(filteredData);
    setRenderItems(filteredData);
  }, [searchString, data]);

  const navigate = useNavigate();
  const goTo = (href) => {
    navigate(href);
  };
  return (
    <Wrapper>
      <Input
        placeholder={i18next.t('searchBar.search')}
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
        onFocus={() => setOpenView(true)}
      />
      {openView && searchString.length > 0 && renderItems.length > 0 && (
        <ViewArea>
          {renderItems.map((el, index) => (
            <ViewItem onClick={() => goTo(el.id)} key={index}>
              <ViewImage src={el.image[0]} />
              <ViewText> {el.name}</ViewText>
            </ViewItem>
          ))}
        </ViewArea>
      )}
      <Icon src="/images/lupa.svg" />
    </Wrapper>
  );
};

export default SearchComponent;

const Wrapper = styled.div`
  width: 100%;
  height: 55px;
  border: 1px solid rgb(211, 47, 47);
  position: relative;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

const ViewArea = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 52px;
  left: -0.5px;
  max-height: 500px;
  overflow: scroll;
  width: calc(100%);
  background-color: white;
  border: 1px solid rgb(211, 47, 47);
  z-index: 5;
`;
const ViewItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  transition: 0.3s;
  &:hover {
    background-color: #ffd8d871;
  }

  cursor: pointer;
`;
const ViewText = styled.div`
  font-size: 18px;
  font-weight: 500;
  padding: 10px 0;
  color: black;
`;
const ViewImage = styled.img`
  width: 20%;
  height: 100%;
  aspect-ratio: 1/1;
`;
const Input = styled.input`
  all: unset;
  padding: 0 10px;
  text-align: start;
  width: 100%;
  box-sizing: border-box;
  font-size: 18px;
  height: inherit;
`;
const Icon = styled.img`
  position: absolute;
  right: 10px;
  width: 40px;
  height: 40px;
`;
