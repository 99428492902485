import styled from 'styled-components';

const ITEMS = [
  {
    title: 'Залишайся на звʼязку',
    description:
      'Миттєве підключення з декількома пристроями Bluetooth або пристроями швидкого заряджання з бездротовою та 36-ватною зарядкою USB-C',
    img: '/modelPages/model3/image2.jpg',
  },
  {
    title: 'Підсилений звук',
    description:
      'Аудіосистема потужністю 960 Вт з 22 динаміками та системою активного шумоподавлення забезпечує найкращі враження від прослуховування, де б ви не знаходилися.',
    img: '/modelPages/model3/image3.jpg',
  },
  {
    title: 'Місце для всього',
    description:
      'Завдяки передньому і задньому багажникам і сидінням, що складаються, ви можете розмістити свій велосипед, не знімаючи колеса, а також свій багаж.',
    img: '/modelPages/model3/image4_2024.jpg',
  },
];

const SecondInfo = () => {
  return (
    <Wrapper>
      <MainContainer>
        <PictureMain src="/modelPages/model3/In3.jpg" />
        <MainTextBlock>
          <Text1>
            Абсолютно
            <br /> новий інтер'єр
          </Text1>
          <Text2>
            Насолоджуйтесь абсолютно новим інтер'єром, який занурює вас у кокон. 
            Налаштуйте навколишнє освітлення, щоб зробити його власним.
          </Text2>
        </MainTextBlock>
      </MainContainer>
      <SecondContainer>
        {ITEMS.map((el, index) => (
          <SecondBlock key={index}>
            <Picture2 src={el.img} />
            <Text3>{el.title}</Text3>
            <Text2>{el.description}</Text2>
          </SecondBlock>
        ))}
      </SecondContainer>
    </Wrapper>
  );
};
export default SecondInfo;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 100px;
  padding: 0 180px;
  margin-top: 150px;
  @media (max-width: 1650px) {
    padding: 0 40px;
    margin-top: 120px;
  }
  @media (max-width: 860px) {
    margin-top: 60px;
  }
`;
const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 53px;
  @media (max-width: 860px) {
    flex-direction: column-reverse;
    row-gap: 30px;
  }
`;
const PictureMain = styled.img`
  width: 60%;
  @media (max-width: 860px) {
    width: calc(100% + 80px);
    margin: 0 -40px;
  }
`;
const Picture2 = styled.img`
  width: 100%;
`;
const MainTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  width: 40%;
  @media (max-width: 860px) {
    width: 100%;
  }
`;
const Text1 = styled.div`
  font-size: 48px;
  font-weight: 500;
  line-height: 67.2px;
  text-align: start;
  @media (max-width: 1160px) {
    font-size: 32px;
    line-height: 30.2px;
  }
`;
const Text2 = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  color: rgba(147, 146, 152, 1);
  text-align: start;
  @media (max-width: 1160px) {
    font-size: 14px;
    line-height: 20.6px;
  }
`;
const Text3 = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 33.2px;
  text-align: start;
  @media (max-width: 1160px) {
    font-size: 18px;
    line-height: 20.6px;
  }
`;
const SecondContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  @media (max-width: 775px) {
    flex-direction: column;
  }
`;
const SecondBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 30%;
  @media (max-width: 775px) {
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(212, 211, 214, 1);
  }
`;
