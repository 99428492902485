import styled from 'styled-components';

const ITEMS = [
  { title: 'text1', desc: 'descdescdescdesc desc desc descdescdesc', img: '' },
  { title: 'text2', desc: 'descdescdescdesc desc desc descdescdesc', img: '' },
  { title: 'text3', desc: 'descdescdescdesc desc desc descdescdesc', img: '' },
];

const Exterier = () => {
  return (
    <>
      <Header>
        <HeaderText>Більше про екстер'єр</HeaderText>
      </Header>
      <Wrapper>
        {/* {ITEMS.map((el, index) => (
          <BlockContainer key={index} bg={el.img}>
            <BlockText>
              <Text1>{el.title}</Text1>
              <Text2>{el.desc}</Text2>
            </BlockText>
          </BlockContainer>
        ))} */}
        <Picture src="/modelPages/Roadster/Ex1.jpg" />
        <Picture src="/modelPages/Roadster/Ex2.jpg" />
        <Picture src="/modelPages/Roadster/Ex3.jpg" />
        <PictureRes src="/modelPages/Roadster/Ex1_mob.jpg" />
        <PictureRes src="/modelPages/Roadster/Ex2_mob.jpg" />
        <PictureRes src="/modelPages/Roadster/Ex3_mob.jpg" />
      </Wrapper>
    </>
  );
};
export default Exterier;

const Header = styled.div`
  background-color: rgba(28, 27, 34, 1);
  padding: 150px 180px 60px;
  @media (max-width: 1650px) {
    padding: 70px 40px 30px;
  }
`;
const HeaderText = styled.div`
  font-size: 48px;
  font-weight: 500;
  color: white;
  @media (max-width: 1000px) {
    font-size: 32px;
  }
  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

const Wrapper = styled.div`
  background-color: rgba(28, 27, 34, 1);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;
const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  background-image: url(${(p) => p.bg});
  background-size: cover;
  background-position: center;
`;
const BlockText = styled.div`
  display: flex;
  flex-direction: column;
`;
const Text1 = styled.div``;
const Text2 = styled.div``;
const Picture = styled.img`
  @media (max-width: 640px) {
    display: none;
  }
`;
const PictureRes = styled.img`
  display: none;
  @media (max-width: 640px) {
    display: block;
  }
`;
