import { useState } from 'react';
import styled from 'styled-components';

const FUTURES_ITEMS = [
  {
    title: 'Навігація на автопілоті',
    desc: 'Активне супроводження від магістралі до з’їзду',
  },
  {
    title: 'Автоматична зміна смуги',
    desc: 'Автоматична зміна смуги під час руху по шосе',
  },
  {
    title: 'Автоматична парковка',
    desc: 'Паралельне та перпендикулярне паркування одним дотиком',
  },
  {
    title: 'Виклик',
    desc: 'Автоматично заберіть свій автомобіль',
  },
];
const CHARACTERISTICS_Y = [
  { title: '3,5 с 0-100 км/год', desc: 'Прискорення' },
  { title: '458 км', desc: 'Запас ходу' },
  { title: '217 км/год', desc: 'Найвища швидкість' },
  { title: '15,7 см', desc: 'Кліренс' },
  { title: '21"', desc: 'Колеса' },
  { title: 'Dual Motor', desc: 'Силовий агрегат' },
  { title: '1996 кг', desc: 'Вага' },
];
const CHARACTERISTICS_Y_LONG_RANGE = [
  { title: '2,5 с 0-100 км/год', desc: 'Прискорення' },
  { title: '539 км', desc: 'Запас ходу' },
  { title: '249 км/год', desc: 'Найвища швидкість' },
  { title: '17,3 см', desc: 'Кліренс' },
  { title: '19" або 20"', desc: 'Колеса' },
  { title: 'Dual Motor', desc: 'Силовий агрегат' },
  { title: '1980 кг', desc: 'Вага' },
];

const Features = () => {
  const [model, setModel] = useState('y');
  const CHARACTERISTICS_RES =
    model === 'y' ? CHARACTERISTICS_Y : CHARACTERISTICS_Y_LONG_RANGE;
  return (
    <Wrapper>
      <RowContainer>
        <TextL>Особливості</TextL>
        <TextS>
          Покращений автопілот і можливість повного автономного водіння вводять
          додаткові функції та покращують наявні функції, щоб з часом зробити
          ваш автомобіль більш продуктивним, зокрема:
        </TextS>
      </RowContainer>
      <FuturesContainer>
        {FUTURES_ITEMS.map((el, index) => (
          <FuturesBlock key={index}>
            <RedNumber>0{index + 1}</RedNumber>
            <ColumnRes>
              <TextM>{el.title}</TextM>
              <TextS style={{ width: '70%' }}>{el.desc}</TextS>
            </ColumnRes>
          </FuturesBlock>
        ))}
      </FuturesContainer>
      <MainContainer>
        <CharContainer>
          <>Model Y Performance</>
          <CharGrid>
            {CHARACTERISTICS_Y.map((el, index) => (
              <CharBlock key={index}>
                <TextM>{el.title}</TextM>
                <TextS>{el.desc}</TextS>
              </CharBlock>
            ))}
          </CharGrid>
        </CharContainer>
        <Picture src="/modelPages/modelY/Futures.jpg" />
        <CharContainer>
          <>Model Y Long Range</>
          <CharGrid>
            {CHARACTERISTICS_Y_LONG_RANGE.map((el, index) => (
              <CharBlock key={index}>
                <TextM>{el.title}</TextM>
                <TextS>{el.desc}</TextS>
              </CharBlock>
            ))}
          </CharGrid>
        </CharContainer>
      </MainContainer>
      <MainContainerRes>
        <ModelsBar>
          <ModelItem active={model === 'y'} onClick={() => setModel('y')}>
          Model Y Performance
          </ModelItem>
          <ModelItem
            active={model === 'long-range'}
            onClick={() => setModel('long-range')}
          >
            Model Y Long Range
          </ModelItem>
        </ModelsBar>

        {CHARACTERISTICS_RES.map((el, index) => (
          <CharItem>
            <Text1>{el.title}</Text1>
            <Text2>{el.desc}</Text2>
          </CharItem>
        ))}
        <PictureRes src="/modelPages/modelY/Futures_mob.jpg" />
      </MainContainerRes>
    </Wrapper>
  );
};
export default Features;

///// Main Container
const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 150px;
  column-gap: 50px;
  @media (max-width: 1360px) {
    display: none;
  }
`;
const MainContainerRes = styled.div`
  display: none;
  flex-direction: column;
  margin-top: 80px;
  @media (max-width: 1360px) {
    display: flex;
  }
`;
const ModelsBar = styled.div`
  display: flex;
`;
const ModelItem = styled.div`
  font-size: 20px;
  color: ${(p) => (p.active ? 'black' : 'gray')};
  border-bottom: 1px solid ${(p) => (p.active ? 'red' : 'gray')};
  padding: 0 15px;
  cursor: pointer;
  width: 100%;
`;
const CharItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid gray;
`;
const Text1 = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: black;
`;
const Text2 = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: black;
`;
const Picture = styled.img``;
const PictureRes = styled.img`
  width: 240px;
  margin: 30px auto;
`;
const CharContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 32px;
  color: black;
  text-align: start;
  width: 100%;
`;
const CharGrid = styled.div`
  margin-top: 60px;
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 45px;
  column-gap: 60px;
`;
const CharBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 10px;
`;

///// Firs Container
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 150px 180px;
  @media (max-width: 1650px) {
    padding: 0 40px;
    margin-top: 150px;
  }
  @media (max-width: 700px) {
    margin-top: 80px;
  }
`;
const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1360px) {
    flex-direction: column;
    align-items: center;
  }
`;
const FuturesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: rgba(249, 249, 249, 1);
  margin-top: 60px;
  padding: 60px;
  column-gap: 70px;
  @media (max-width: 1360px) {
    flex-direction: column;
    align-items: center;
    row-gap: 50px;
  }
  @media (max-width: 500px) {
    padding: 30px 20px;
  }
`;
const FuturesBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 10px;
  @media (max-width: 1360px) {
    width: 100%;
    flex-direction: row;
  }
`;
const RedNumber = styled.div`
  font-weight: 500;
  font-size: 32px;
  color: rgba(226, 5, 49, 1);
  margin-bottom: 10px;
  @media (max-width: 1360px) {
    margin-right: 20px;
  }
  @media (max-width: 700px) {
    font-size: 20px;
  }
`;
const TextL = styled.div`
  font-weight: 500;
  font-size: 48px;
  color: black;

  @media (max-width: 700px) {
    font-size: 24px;
    margin-right: auto;
    margin-bottom: 15px;
  }
`;
const TextM = styled.div`
  font-weight: 500;
  font-size: 24px;
  color: black;
  text-align: start;
  @media (max-width: 700px) {
    font-size: 18px;
  }
`;
const TextS = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: rgba(147, 146, 152, 1);
  max-width: 621px;
  text-align: start;
  @media (max-width: 700px) {
    font-size: 12px;
  }
`;
const ColumnRes = styled.div`
  display: flex;
  flex-direction: column;
`;
