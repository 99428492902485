import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { FreeMode, Navigation } from 'swiper';

import 'swiper/css';
import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import useContentful from '../../../../contentful';
import CardItem from '../../../MainPage/CardItem';
import { Link } from 'react-router-dom';

const SliderItem = ({ data }) => {
  return (
    <SliderItemWrapper>
      <CardItem data={data} />
    </SliderItemWrapper>
  );
};

const Slider2 = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('X');
  const { getItems } = useContentful();

  useEffect(() => {
    getItems(setLoading).then((response) => setItems(response));
  }, []);
  const filtredItems = items.filter((el) => el.model === filter);
  const navigationNextRef = useRef(null);
  const navigationPrevRef = useRef(null);
  return (
    <>
      {filtredItems.length && (
        <>
          <HeaderWrapper>
            <MainTitle>Каталог авто</MainTitle>
            <FiltersWrapper>
              <FilterText
                active={filter === 'S'}
                onClick={() => setFilter('S')}
              >
                Model S
              </FilterText>
              <Dot />
              <FilterText
                active={filter === '3'}
                onClick={() => setFilter('3')}
              >
                Model 3
              </FilterText>
              <Dot />
              <FilterText
                active={filter === 'X'}
                onClick={() => setFilter('X')}
              >
                Model X
              </FilterText>
              <Dot />
              <FilterText
                active={filter === 'Y'}
                onClick={() => setFilter('Y')}
              >
                Model Y
              </FilterText>
              <Link to={'/katalog-auto'}>
                <Button>Повний каталог</Button>
              </Link>
            </FiltersWrapper>
          </HeaderWrapper>

          <Wrapper>
            <ArrowL ref={navigationPrevRef} />
            <ArrowR ref={navigationNextRef} />
            <Swiper
              loop
              pagination={{ clickable: true }}
              spaceBetween={30}
              slidesPerView={'auto'}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
              }}
            >
              {filtredItems.map((el, index) => (
                <SwiperSlide
                  key={index}
                  style={{ width: 'auto', height: '100%' }}
                >
                  <SliderItem data={el} />
                </SwiperSlide>
              ))}
            </Swiper>
          </Wrapper>
          <Link to={'/katalog-auto'}>
            <ButtonRes>Повний каталог</ButtonRes>
          </Link>
        </>
      )}
    </>
  );
};
export default Slider2;

const SliderItemWrapper = styled.div`
  width: 487px;
  height: auto;
  @media screen and (max-width: 461px) {
    width: 260px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.vidhuky ? '0' : '0 180px')};
  transition: all 1s;
  position: relative;
  margin-bottom: 150px;
  @media screen and (max-width: 1650px) {
    padding: ${(props) => (props.vidhuky ? '0' : '0 40px')};
  }
  @media screen and (max-width: 1110px) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 380px) {
    padding: ${(props) => (props.vidhuky ? '0' : '0 27px')};
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 180px;
  align-items: center;
  margin-top: 150px;
  margin-bottom: 65px;
  @media screen and (max-width: 1650px) {
    padding: 0 40px;
  }
  @media screen and (max-width: 1110px) {
    flex-direction: column;
  }
`;
const FiltersWrapper = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 20px;
`;
const FilterText = styled.div`
  color: ${(p) =>
    p.active ? 'rgba(226, 5, 49, 1)' : 'rgba(147, 146, 152, 1)'};
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    color: 'rgba(226, 5, 49, 1)';
  }
`;
const Dot = styled.div`
  width: 5px;
  height: 5px;
  background-color: rgba(226, 5, 49, 1);
`;
const Button = styled.div`
  width: 216px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(226, 5, 49, 1);
  cursor: pointer;
  margin-left: 45px;
  @media screen and (max-width: 1110px) {
    display: none;
  }
`;
const ButtonRes = styled.div`
  width: 260px;
  height: 48px;
  display: none;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(226, 5, 49, 1);
  cursor: pointer;
  margin: 0 auto;
  @media screen and (max-width: 1110px) {
    display: flex;
    margin-bottom: 80px;
  }
`;
const MainTitle = styled.div`
  font-weight: 500;
  font-size: 48px;
  text-align: start;
  @media screen and (max-width: 700px) {
    font-size: 24px;
    margin-right: auto;
    margin-bottom: 30px;
  }
`;
const ArrowL = styled.div`
  width: 45px;
  height: 45px;
  margin-top: 100px;
  margin: 32px;

  transition: 0.3s;
  background-image: url('/images/Arrow.jpg');
  background-position: center;
  cursor: pointer;
  position: absolute;
  height: 45px;
  z-index: 200;
  left: 125px;
  top: 105px;
  @media screen and (max-width: 1650px) {
    left: ${(props) => (props.vidhuky ? '-53px' : '-13px')};
    top: 105px;
  }
  @media screen and (max-width: 461px) {
    top: 45px;
  }
  @media screen and (max-width: 380px) {
    width: 35px;
    height: 35px;
  }
`;
const ArrowR = styled.div`
  width: 45px;
  transition: 0.3s;
  background-image: url('/images/Arrow_rev.jpg');
  background-position: center;
  cursor: pointer;
  margin-top: 100px;
  margin: 32px;
  cursor: pointer;
  position: absolute;
  height: 45px;
  z-index: 200;
  right: 125px;
  top: 105px;
  @media screen and (max-width: 1650px) {
    right: ${(props) => (props.vidhuky ? '-53px' : '-13px')};
    top: 105px;
  }
  @media screen and (max-width: 461px) {
    top: 45px;
  }

  @media screen and (max-width: 380px) {
    width: 35px;
    height: 35px;
  }
`;
