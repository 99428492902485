import styled from 'styled-components';


const ImageCredits = () => {
  return (
    <>
      <TextCredits><strong>*</strong> Всі зображення для цієї сторінки взяті з фотостоків та відкритих джерел. Авторські права належать їх власникам.</TextCredits>
    </>
  );
};
export default ImageCredits;


const TextCredits = styled.p`
  padding: 0 180px;
  margin-bottom: 50px;
  text-align: start;
  color: rgb(147, 146, 152);
  font-size: 16px;
  @media (max-width: 450px) {
    padding: 0 30px;
    font-size: 12px;
  }
`;
